import Layout from "components/layout/defaultLayout";
import dynamic from "next/dynamic";
import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import errorAnimation from "../public/assets/animations/error.json";
import { logoUrl } from "constant/main";

const Lottie = dynamic(() => import("lottie-react"), {
  ssr: false,
});
export default function NotFound() {
  const { locale } = useRouter();
  return (
    <Layout>
      <main className="flex flex-col items-center justify-center flex-grow md:justify-start md:mt-14">
      <Image
          width={250}
          alt="Logo"
          height={100}
          src={locale === "en" ? logoUrl.en : logoUrl.ar}
          className="hidden mb-3 md:block p-3"
        />
        <Lottie
          animationData={errorAnimation}
          loop={true}
          width={2}
          className="mb-3 md:hidden "
        />
        <h1 className="text-lg">
          404 | This page is not found , please go back to home page or try
          again
        </h1>
        <Link
          href="/"
          style={{ fontFamily: "RobotoSemiBold" }}
          className="mt-5 text-primary link-hover"
        >
          Go back to home
        </Link>
      </main>
    </Layout>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? "en")),
    },
  };
};
